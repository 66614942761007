@font-face {
  font-family: nunitoSemi;
  src: url("Nunito-SemiBold.a82221d3.ttf") format("truetype");
}

@font-face {
  font-family: nunitoBold;
  src: url("Nunito-Bold.0459fa02.ttf") format("truetype");
}

@font-face {
  font-family: CGBold;
  src: url("CormorantGaramond-Bold.d932f670.ttf") format("truetype");
}

#floating-header {
  display: none !important;
}

.cookie-banner.no-show {
  display: none;
}

.cookie-text {
  max-width: 60%;
}

.cookie-banner {
  z-index: 103;
  letter-spacing: normal;
  text-align: left;
  color: #f0f0f0;
  width: 100%;
  background-color: #242222;
  flex-direction: column-reverse;
  justify-content: space-around;
  padding: 20px 20px 40px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.5;
  display: flex;
  position: fixed;
  bottom: 0;
}

.cookie-banner a {
  font-size: 12px;
  text-decoration: underline;
}

.cookie-banner a:hover {
  color: #f0f0f0;
}

.cookie-banner span {
  color: #fff;
  font-weight: bold;
}

.cookie-close {
  text-align: right;
  padding-right: 50px;
}

.cookie-close img.x-ic {
  width: 25px;
  height: 25px;
  object-fit: contain;
  opacity: .8;
  cursor: pointer;
}

.cookie-close img.x-ic:hover {
  opacity: 1;
  transform: scale(1.05);
}

.hamburger {
  width: 25px;
  height: 25px;
  opacity: .8;
  cursor: pointer;
  z-index: 10;
  padding: 0 40px 20px 0;
  transition: all .5s;
  position: absolute;
}

.hamburger:hover {
  opacity: 1 !important;
}

.hamburger.x .burgerLine {
  width: 20px;
}

.hamburger .burgerLine {
  height: 2px;
  opacity: 1;
  width: 25px;
  transition: all .5s;
  position: absolute;
}

.hamburger.dark .burgerLine {
  background-color: #c5c6bf;
}

.hamburger.light .burgerLine {
  background-color: #fff;
}

.burgerLine1 {
  margin-top: 4px;
}

.burgerLine2 {
  margin-top: 12px;
}

.burgerLine3 {
  margin-top: 20px;
}

#main-nav.dark-theme .links .link-header {
  color: #575755;
}

#main-nav {
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  overflow: unset;
  background-color: #fff;
  flex-direction: column;
  margin-top: 0;
  padding: 0 64px;
  display: flex;
  position: fixed;
  left: 0;
  box-shadow: 0 2px 2px -2px #0006;
}

#main-nav.expandedNav {
  height: 100%;
  background-color: #fff;
  transition: height .3s ease-out, box-shadow .2s;
  overflow: hidden;
  box-shadow: 0 0 50px 10px #00000080;
}

#desktop-nav-wrapper {
  max-width: 1512px;
  background-color: #fff;
  margin: 0 auto;
}

.dog-container {
  display: inline-block;
  position: relative;
}

.dog-logo {
  height: 26px;
  width: 81px;
  cursor: pointer;
  background: url("white-text.96be5898.svg") no-repeat;
  transform: translate(-50%);
  background-size: contain !important;
}

.dog-logo.current-logo {
  z-index: 5;
}

.dog-logo.transparent {
  opacity: 0;
}

.dog-logo.faded {
  transition: opacity .3s ease-in-out;
}

.dog-logo.dog-swap {
  position: absolute;
  top: 0;
  left: 0;
}

.dog-logo.white-text {
  z-index: 3;
  background: url("white-text.96be5898.svg") no-repeat;
  position: relative;
}

.dog-logo.dark-text-mob, .dog-logo.dark-text {
  z-index: 3;
  background: url("dark-text.59029328.svg") no-repeat;
}

.dog-logo.white-head {
  background: url("white-dog-head.f0068ef5.svg") no-repeat;
}

.dog-logo.dark-head {
  background: url("dark-dog-head.86cc6ab0.svg") no-repeat;
}

div#backToTop {
  background: url("white-text.96be5898.svg") -9999px -9999px no-repeat;
}

.info-link-container {
  background: url("dark-text.59029328.svg") -9999px -9999px no-repeat;
}

div.new-footer {
  background: url("white-dog-head.f0068ef5.svg") -9999px -9999px no-repeat;
}

div.bottom-container {
  background: url("dark-dog-head.86cc6ab0.svg") -9999px -9999px no-repeat;
}

.link-cont {
  margin-bottom: 20px;
  display: flex;
}

.nav-arrow {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 10px;
  display: block;
}

.nav-arrow.nav-up {
  background: url("nav-up.69cfcd02.svg") no-repeat;
}

.nav-arrow.nav-down {
  background: url("nav-down.f7b69aa8.svg") no-repeat;
}

.links.initial {
  visibility: hidden;
}

.links {
  text-align: left;
  width: calc(100vw - 40px);
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 60px;
  display: flex;
  position: relative;
}

.links .link-header {
  letter-spacing: normal;
  color: #575755;
  height: 25px;
  font-family: nunitoBold;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 25px;
}

.sub-links {
  height: 100%;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
}

.sub-links a:hover {
  text-decoration: underline;
}

.sub-links a {
  letter-spacing: normal;
  width: 150px;
  margin-bottom: 10px;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.42;
  color: #575755 !important;
}

.not-mobile, .not-tablet {
  display: none !important;
}

::-moz-selection {
  color: #fff;
  background: #333;
}

::selection {
  color: #fff;
  background: #333;
}

body {
  max-width: 100%;
  background-color: #f7f7f7;
  margin: 0;
}

#newbase-content, #fullpage {
  width: 100%;
  background-color: #f7f7f7;
  overflow: hidden;
}

.section {
  z-index: 1;
  text-align: center;
  transition: transform .5s, top .5s, bottom .5s;
  top: 0;
  bottom: 0;
}

#section0 {
  opacity: 1;
  z-index: 3;
  background: url("https://d35408cyocioye.cloudfront.net/new3/josh-hero.jpg") 50% 0 / cover;
}

#section1 {
  opacity: 1;
  z-index: 3;
  background: url("https://d35408cyocioye.cloudfront.net/new3/josh-introducting-micro-still.jpg") 50% 0 / cover;
}

#section2 {
  opacity: 1;
  z-index: 3;
  background: url("josh-getjosh-ski-mobile-2.42b65ff3.jpg") 50% 0 / cover;
  overflow: hidden;
}

#section3 {
  opacity: 1;
  z-index: 2;
  background: url("https://d35408cyocioye.cloudfront.net/new3/josh-getjosh-shades.jpg") 50% 0 / cover;
  top: -50vh;
  overflow: hidden;
}

#section4 {
  z-index: 1;
  opacity: 1;
  background: url("https://d35408cyocioye.cloudfront.net/new3/josh-getjosh-lights-off.jpg") 50% 0 / cover;
  top: -100vh;
  overflow: hidden;
}

.sectionTextContainer {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.sectionText {
  text-align: center;
  color: #fff;
  opacity: 0;
  max-width: 280px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 1s;
}

#section1 .sectionText {
  max-width: 880px;
}

.sectionText.dark {
  color: #575755;
}

.sectionTextLarge {
  font-size: 24px;
  font-weight: 300;
}

.microText.sectionTextLarge {
  font-size: 18px;
}

#backToTop {
  opacity: 0;
  height: 30px;
  width: 150px;
  z-index: 102;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  transition: right .2s, opacity .5s;
  display: none;
  position: fixed;
  bottom: 60px;
  right: -110px;
}

#backToTop:hover {
  opacity: 1 !important;
}

.verticalDownLine {
  height: 15px;
  width: 2px;
  background: #fff;
  border-radius: 2px;
  position: relative;
  top: 25%;
  left: 15px;
}

#backToTop p {
  color: #fff;
  margin-top: 3px;
  margin-left: 40px;
  position: absolute;
  top: 0;
}

.horiDownLine {
  height: 10px;
  width: 2px;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  top: 6px;
}

.horiDownLine1 {
  left: 12px;
  transform: rotate(45deg);
}

.horiDownLine2 {
  left: 18px;
  transform: rotate(-45deg);
}

#landingContainer {
  z-index: 1;
  -webkit-transition: height .7s, width .7s;
  -webkit-backface-visibility: hidden;
  background-position-x: 50%;
  transition: height .7s, width .7s, background .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.landingContainer1 {
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  background: #fff url("https://d35408cyocioye.cloudfront.net/new3/josh-hero.jpg") 50% 0 / cover;
}

#landingContainer iframe {
  display: none;
}

.landingContainer2 {
  width: 90%;
  height: 60%;
  cursor: pointer;
  background: #fff url("https://d35408cyocioye.cloudfront.net/new3/josh-introducting-micro-still.jpg") 50% 0 / cover;
  box-shadow: 0 10px 15px -2px #a1a1a17d;
}

.landingContainer2.grow {
  width: 75%;
  height: 42.1875vw;
}

.landingContainer2:hover {
  box-shadow: 0 10px 15px -2px #7878787d;
}

#landingJoshLogo {
  height: 52px;
  width: 150px;
  opacity: 0;
  background: url("white-text.96be5898.svg") 0 0 / cover no-repeat;
  margin: auto auto 20px;
  transition: opacity .3s;
  transform: scale(.75);
}

.landingCopy {
  opacity: 0;
  transition: opacity .5s;
}

.landingCopyContainer {
  transition: opacity .3s;
}

.secondCopyContainer {
  opacity: 0;
  transition: opacity .5s;
}

#landingVid {
  pointer-events: none;
  height: 100%;
  z-index: -100;
  object-fit: cover;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  display: block;
  position: absolute;
  overflow-y: hidden;
}

#landingVid2 {
  height: 100%;
  object-fit: cover;
  width: 100%;
  z-index: -100;
  opacity: 0;
  display: block;
  position: absolute;
  overflow-y: hidden;
}

#secondCopy {
  z-index: 1;
  display: block;
}

.fade-in-video {
  opacity: 0;
  transition: opacity .5s linear;
}

.fade-in-video.is-playing {
  opacity: 1 !important;
}

#mainVideo {
  width: 100%;
  height: 560px;
  background-color: #0000;
  background-image: linear-gradient(#0000001a, #0000001a), url("https://d35408cyocioye.cloudfront.net/new2/video.jpg");
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding-top: 46px;
  position: relative;
}

#mainVideo:hover {
  cursor: pointer;
}

#mainVideo:hover > .integratorTopPlayButton {
  cursor: pointer;
  background-position: 0 51px;
}

.ignore-css {
  all: unset;
}

.landingPlayButton {
  height: 39px;
  width: 39px;
  opacity: .7;
  background: url("https://d35408cyocioye.cloudfront.net/new3/ic-play.svg") no-repeat;
  margin: 15px auto auto;
  transition: all .5s;
}

.landingPlayButton:hover {
  cursor: pointer;
}

@keyframes scrollDown {
  from {
    height: 0;
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    height: 100px;
    opacity: 1;
    transform: translateY(100px);
  }
}

.downArrowLine {
  height: 0;
  width: 1px;
  opacity: 0;
  background-color: #fff;
  transition: all 1s ease-in;
  position: absolute;
  top: 0;
  left: 49%;
}

.downArrowLine.animated {
  animation: .7s infinite scrollDown;
}

.scrollDownArrow {
  height: 35px;
  width: 30px;
  opacity: 0;
  z-index: 13;
  cursor: pointer;
  margin: 10px auto auto;
  transition: all 1s;
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
}

.arrowLines {
  height: 1px;
  width: 0;
  background-color: #fff;
  transition: all .3s;
  position: absolute;
  top: 50%;
}

.arrowLines.darkArrow {
  background-color: #999;
}

.scrollDownArrow.dark > .arrowLines {
  background-color: #000;
}

.arrowLines1 {
  left: 50%;
}

.arrowLines2 {
  right: 50%;
}

.fullNav {
  z-index: 0;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  background-color: #272a2d;
  transition: all .5s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

.fullNavList {
  height: 110vh;
  margin-top: 6vh;
  padding-left: 0;
}

#fullNavListLeft {
  width: 70%;
  padding-left: 30vw;
}

#fullNavListMid {
  margin-top: calc(6vh + 78px);
  position: absolute;
  left: 45vw;
  transform: translateX(-40%);
}

#fullNavListRight {
  height: auto;
  margin-top: calc(6vh + 760px);
  position: absolute;
  left: 30vw;
}

.fullNavText {
  text-align: left;
  opacity: 0;
  margin-bottom: 15px;
  list-style: none;
  transform: translate3d(-30px, 0, 0);
}

.fullNavText.view, .horizontalAboutLine.view {
  opacity: 1;
  transition: all .15s ease-out;
  transform: translate3d(0, 0, 0);
}

.horizontalAboutLine.view {
  opacity: .25;
  transition: all .2s ease-out;
}

.horizontalAboutLine.view:before {
  width: 7vw;
  transition: all .3s ease-out;
}

.fullNavText.leave, .horizontalAboutLine.leave {
  opacity: 0;
  transition: all 50ms ease-in;
  transform: translate3d(-50px, 0, 0);
}

.fullNavText.active {
  border-left: 2px solid #008cff;
  margin-left: -20px;
  padding-left: 20px;
}

.fullNavText.active a {
  opacity: .9;
}

.fullNavText a {
  opacity: .7;
  color: #fff;
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  position: relative;
}

.aboutLine {
  width: 100%;
  height: 1px;
  background: #fff;
  display: block;
  position: relative;
  top: -1rem;
  left: 6rem;
}

.horizontalAboutLine {
  width: 0;
  height: 11rem;
  opacity: .25;
  border: 1px solid #fff;
  margin-left: -20px;
  position: absolute;
  top: .25rem;
}

.horizontalAboutLine:before {
  content: "";
  width: 0;
  height: 0;
  border: none;
  margin-left: -7.2vw;
  position: absolute;
  top: 4rem;
}

#fixedHeader.pinned {
  position: absolute !important;
}

#fixedHeader {
  height: 40px;
  z-index: 12;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  justify-content: space-between;
  transition: all 1s;
  display: flex;
  position: fixed;
  left: 0;
  overflow: hidden;
}

.fixedHeaderPosition, .fixedHeaderSplash {
  width: calc(100% - 100px);
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 50px;
}

#joshdoghead {
  height: auto;
  width: 115px;
  z-index: 1;
  opacity: 0;
  background: url("white-text.96be5898.svg") 0 0 / cover no-repeat;
  transition: all .5s;
}

.headerLogin {
  color: #fff;
  opacity: 0;
  z-index: 1;
  line-height: 40px;
  transition: all .5s;
}

.headerLogin:hover {
  opacity: 1 !important;
}

.featureVideo {
  pointer-events: none;
  z-index: -100;
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: block;
  position: absolute;
  overflow-y: hidden;
}

.replay {
  width: 35px;
  height: 40px;
  object-fit: contain;
  opacity: 0;
  cursor: pointer;
  margin-bottom: 25px;
  margin-right: 50px;
  transition: all .5s ease-in;
  position: absolute;
  bottom: 0;
  right: 0;
}

.replayVisible {
  opacity: 1;
}

.replayVisible:hover {
  opacity: .8;
}

.heyJoshContainer {
  width: 95vw;
  z-index: -1;
  color: #fff;
  opacity: 0;
  text-align: left;
  -webkit-backface-visibility: hidden;
  font-size: 3rem;
  font-weight: 300;
  transition: all 1s;
  position: fixed;
  top: 50%;
  left: 5vw;
  transform: translateY(-50%);
}

.heyJoshText {
  transition: opacity;
}

.heyJoshRequest {
  width: auto;
  opacity: 0;
  border-bottom: none;
  margin-top: 1rem;
  padding-bottom: 6.5px;
  transition: opacity .3s, transform .5s;
  display: inline-block;
}

.new-footer {
  z-index: 100;
  position: relative;
}

.new-footer_next {
  z-index: 100;
  width: 100vw;
  background-color: #fff;
  position: relative;
  overflow-y: hidden;
}

.next-container.long {
  width: 80% !important;
}

.next-container {
  width: 50%;
  cursor: pointer;
  margin: 15vh auto;
}

.next-up-content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.next-up-header {
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: #575755;
  max-width: 100vw;
  margin-bottom: 40px;
  font-family: nunitoBold;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
}

.next-container.new-prod-foot {
  width: 80%;
  margin: 80px auto 100px;
}

.next-flex {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.next-item.get {
  margin-right: 0 !important;
}

.next-item {
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  transition: all .3s;
  display: flex;
}

.next-item:hover {
  transform: scale(1.2);
}

.next-img.nano {
  height: 50px;
  width: 50px;
  background-image: url("https://joshai.s3.amazonaws.com/new3/homepage/next-up-nano.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.next-img.micro {
  height: 100px;
  width: 100px;
  background-image: url("https://joshai.s3.amazonaws.com/new3/homepage/next-up-micro.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.next-img.get {
  height: 85px;
  width: 150px;
  background-image: url("https://joshai.s3.amazonaws.com/new3/homepage/next-up-get-josh.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.next-title {
  letter-spacing: normal;
  text-align: center;
  color: #575755;
  margin-bottom: 15px;
  font-family: nunitoBold;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
}

.new-footer_info {
  width: 100vw;
  background-color: #111;
  overflow-y: hidden;
}

.info-container {
  width: 85vw;
  max-width: 500px;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  padding: 40px 0;
  display: flex;
}

.info-link-container {
  flex-direction: column;
  display: flex;
}

.info-links {
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  display: flex;
}

.info-link-col {
  align-self: center;
  display: flex;
}

.info-link-line {
  width: 0;
  height: 200px;
  opacity: .3;
  border: 1px solid #fff;
  display: none;
}

.info-link {
  opacity: .6;
  letter-spacing: normal;
  margin-bottom: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
}

.info-link:nth-child(4) {
  margin-bottom: 0;
}

.info-link:hover {
  opacity: 1;
}

.info-story-content {
  letter-spacing: normal;
  color: #bbc6d0;
  width: 100%;
  text-align: justify;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.67;
}

.info-story-text {
  letter-spacing: normal;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.25;
}

.info-story-dog img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-bottom: 15px;
  margin-right: 15px;
}

.info-story-header {
  display: flex;
}

.info-story-container {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
  display: flex;
}

.info-award-container {
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  display: flex;
}

.info-award img {
  height: 50px;
}

.info-award {
  margin-bottom: 20px;
}

.info-award:nth-child(3) {
  margin-bottom: 0;
}

.info-award.award-badge {
  align-items: center;
  display: flex;
}

.award-badge img {
  width: 42px;
}

.award-title {
  letter-spacing: normal;
  color: #bbc6d0;
  flex-direction: column;
  align-items: baseline;
  margin-left: 43px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: .8;
  display: flex;
}

.award-title span {
  font-weight: bold;
}

.new-footer_bottom {
  width: 100vw;
  background-color: #000;
  overflow-y: hidden;
}

.bottom-container {
  width: 85vw;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 25px 0;
  display: flex;
}

.bottom-social-container {
  margin-bottom: 20px;
  display: flex;
}

.bottom-social img {
  opacity: .7;
  height: 19px;
  width: 19px;
  margin-top: 5px;
  margin-right: 20px;
  padding-right: 20px;
  transform: scale(1) !important;
}

.bottom-social img:hover {
  opacity: 1;
}

.bottom-link {
  letter-spacing: normal;
  text-align: center;
  color: #bbc6d0;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
}

a .bottom-link:hover {
  color: #fff;
}

.bottom-link-container.mobile-bottom-links {
  max-width: 350px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.bottom-link-container.desk-bottom-links {
  display: none;
}

.bottom-link-container a {
  margin-right: 0;
}

.bottom-link.bottom-privacy {
  margin-bottom: 10px;
  margin-right: 40px;
}

#sectionFoot {
  display: none;
}

#email_status.enterText {
  color: #fff;
  margin-bottom: 80px;
  margin-left: 15px;
  font-size: 11px;
  position: absolute;
}

.nextUp {
  height: 50vh;
  width: 100vw;
  background: #fff;
  position: absolute;
  bottom: 50vh;
}

.footerTextContainer {
  width: 90%;
  cursor: pointer;
  cursor: pointer;
  position: absolute;
  bottom: 20vh;
}

.footerText {
  text-align: center;
  color: #575755;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.67;
}

.footerTextMain:hover {
  text-decoration: underline;
}

.footerTextSecondary {
  color: #9a9a95;
  letter-spacing: 5px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.rightArrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
}

.rightArrowLines {
  height: 12px;
  width: 2px;
  background-color: #008cff;
  position: absolute;
}

.rightArrowLines:hover {
  background-color: #5c7385;
}

.rightArrowLines1 {
  transform: rotate(-45deg);
}

.rightArrowLines2 {
  margin-top: 8px;
  transform: rotate(45deg);
}

.fullFooter {
  height: 60vh;
  width: 100%;
  background: #303c45;
  position: absolute;
  bottom: 0;
}

.fullFooterText {
  text-align: left;
  opacity: .7;
  margin-bottom: 1rem;
  list-style: none;
}

.fullFooterText a {
  font-weight: 300;
  transition: opacity .3s;
  position: relative;
}

.fullFooterText:hover {
  opacity: 1;
}

.footerLeftLinks {
  margin-left: 20vw;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
}

.footerRightLinks {
  position: absolute;
  top: 55%;
  right: 20vw;
  transform: translateY(-50%);
}

.footerDivider {
  width: 0;
  height: 17.5vh;
  opacity: .25;
  border: 1px solid #fff;
  position: absolute;
  top: 37.5%;
  left: 50%;
  transform: translateY(-50%);
}

.footerEmail {
  top: 37.5%;
}

.joinText {
  width: 100%;
  letter-spacing: 5px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 50%);
}

#emailform {
  width: 235px;
  height: 40px;
  opacity: .5;
  -webkit-highlight: none;
  letter-spacing: normal;
  color: #fff;
  background: none;
  border: 2px solid #bbc6d0;
  border-radius: 100px;
  outline: none;
  padding-left: 15px;
  padding-right: 70px;
  font-family: Lato;
  font-size: 15px;
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.67;
}

#emailform:focus {
  opacity: 1 !important;
}

.bottom-email-container {
  height: 130px;
  margin-bottom: 30px;
}

.btn-bg {
  cursor: pointer;
  opacity: .5;
  background: none;
  border: none;
  border-radius: 23px;
  outline-width: 0;
  margin-left: -80px;
}

.btn-txt {
  height: 15px;
  width: 70px;
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
  padding: 20px 0;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  transform: translateY(-1px);
}

.btn-bg:hover {
  opacity: 1;
}

.bottomFooter {
  height: 20%;
  width: 100%;
  background: #263037;
  position: absolute;
  bottom: 0;
}

.bottomFooterLayout {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.bottomFooterText {
  opacity: .5;
  text-align: center;
  color: #fff;
  padding-right: 4vw;
  font-size: 14px;
}

.bottomFooterLayout a .bottomFooterText {
  opacity: .5;
  font-weight: 500;
}

.bottomFooterLayout a:hover > .bottomFooterText {
  opacity: 1;
}

.socialIcons {
  height: 36px;
  padding: 0 4vw;
}

.socialIcons img {
  opacity: .7;
  margin-top: 5px;
  transform: scale(1) !important;
}

.socialIcons img:nth-child(1) {
  height: 19px;
  width: 19px;
}

.socialIcons img:nth-child(2) {
  height: 20px;
  width: 10px;
}

.socialIcons img:nth-child(3) {
  height: 24px;
  width: 24px;
}

.socialIcons img:hover {
  opacity: 1;
}

.priTermsContainer {
  display: inline-flex;
}

.bottomFooter img {
  height: -moz-fit-content;
  height: fit-content;
  padding-right: 8vw;
  transform: scale(.8);
}

#instaLogo {
  margin-right: 0;
  padding-right: 0;
}

.microSection {
  opacity: 1;
  z-index: 3;
  background-color: #f7f7f7;
  background-position-x: 50%;
  background-size: cover;
  top: -100vh;
  overflow: hidden;
}

.lastSection {
  z-index: 5;
  opacity: 1;
  height: 50%;
  background-color: #f7f7f7;
  transition: all .5s;
  top: -100vh;
}

.microContainer {
  display: none;
}

.wrapper {
  height: 20vh;
  margin: 0 auto;
  overflow-y: scroll;
}

.micro-anim {
  opacity: 1;
  z-index: 0;
  height: 100vh;
  width: 100%;
  -webkit-backface-visibility: hidden;
  background: url("https://d35408cyocioye.cloudfront.net/new3/microsegments/micro-anim-0.jpg") center / cover;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.micro-anim.locked {
  background: url("https://d35408cyocioye.cloudfront.net/new3/microsegments/micro-anim-31.jpg") !important;
  display: none !important;
}

.microText {
  width: 75%;
  -webkit-backface-visibility: hidden;
  transition: opacity .5s, top .1s;
  position: absolute;
  top: 10%;
  left: 50vw;
  transform: translate(-50%);
  opacity: 0 !important;
}

.microTextSecondary {
  text-align: center;
  color: #575755;
  width: 90vw;
  -webkit-backface-visibility: hidden;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.75;
  transition: opacity 1s;
  position: absolute;
  left: 5vw;
  opacity: 0 !important;
}

#microTextSecondary1 {
  top: 25%;
}

#microTextSecondary2 {
  top: 40%;
}

#microTextSecondary3 {
  top: 57%;
}

#microTextSecondary4 {
  top: 75%;
}

.microTextSecondary p {
  color: #9a9a95;
  margin-top: 5px;
  font-size: 15px;
  line-height: 1.67;
  transition: opacity 1s;
}

.microText.shown {
  opacity: 1 !important;
}

.microTextSecondary.shown {
  transition: opacity 3s .5s;
  opacity: 1 !important;
}

#transMicro {
  height: 100%;
  width: 100%;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  background: url("https://d35408cyocioye.cloudfront.net/new3/microsegments/micro-anim-freeze-31.png") center / cover;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

#verticalLine {
  width: 3px;
  height: 70vh;
  opacity: .5;
  background-color: #999;
  position: absolute;
  top: 50vw;
  left: 50vw;
}

.lastMicroTextContainerR {
  color: #575755;
  height: 70vh;
  width: 50vw;
  z-index: 1;
  text-align: left;
  position: absolute;
  top: 50vw;
  left: 49vw;
}

.lastMicroTextContainerL {
  color: #575755;
  height: 70vh;
  width: 52vw;
  text-align: right;
  z-index: 1;
  position: absolute;
  top: 50vw;
}

#lastMicroBR {
  position: relative;
  top: 45vh;
}

#lastMicroTLContainer {
  display: inline-flex;
  position: relative;
  top: 5vh;
}

#lastMicroTL {
  width: 40vw;
  position: relative;
  left: -1vw;
}

#lastMicroBL {
  width: 40vw;
  position: absolute;
  top: 70vh;
  left: 52vw;
  transform: translate(-100%, -70%);
}

#lastMicroTR {
  position: relative;
  top: -10px;
}

#lastMicro {
  height: 40%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  background: url("third_section.be4229a1.png") center / 80% no-repeat;
  position: relative;
  top: 50vh;
  transform: translateY(-30%);
}

#transMicro.big {
  height: 400px;
  width: 400px;
  top: 64%;
}

#transMicro.transVisible {
  display: block;
}

#brandWall {
  -webkit-backface-visibility: hidden;
  opacity: 0;
  animation: 50s linear infinite scrollAcross;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#brandWall.activated {
  opacity: 1;
}

.brandCol {
  flex-direction: column;
  margin-right: 60px;
  display: flex;
}

.brandCol img {
  width: 120px;
  height: 60px;
  margin-bottom: 20px;
}

@keyframes scrollAcross {
  from {
    left: 0;
  }

  to {
    left: -2520px;
  }
}

@keyframes centerOutFirst {
  from {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  to {
    top: 30%;
    left: 17vw;
  }
}

@keyframes centerOutSecond {
  from {
    top: 50%;
    right: 50%;
    transform: translate(100%, -50%);
  }

  to {
    top: 30%;
    right: 17vw;
  }
}

@keyframes centerOutThird {
  from {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  to {
    top: 60%;
    left: 17vw;
  }
}

@keyframes centerOutFourth {
  from {
    top: 50%;
    right: 50%;
    transform: translate(100%, -50%);
  }

  to {
    top: 60%;
    right: 17vw;
  }
}

svg {
  display: none;
}

.page {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: block;
}

.container {
  width: 100%;
  height: 100%;
  z-index: 100;
  display: table;
}

.child {
  display: table-row;
}

.content {
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.HoverButton {
  z-index: 20;
  padding: 0 5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.HoverButton .bg {
  pointer-events: none;
  height: 100%;
  width: 125%;
  background-color: #000;
  display: block;
  position: absolute;
  transform: translateX(-112%)skew(-10deg);
}

h1 {
  color: #000;
  letter-spacing: normal;
  z-index: 1;
  mix-blend-mode: difference;
  margin: 0;
  font-family: Lato;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.33;
  position: relative;
}

.HoverButton:hover > h1 {
  color: #fff;
}

#cursor {
  display: none;
}

#cursor.visible {
  display: block;
}

.Cursor {
  pointer-events: none;
  transform-origin: center;
  mix-blend-mode: difference;
  z-index: 1000;
  -webkit-filter: url("#goo");
  filter: url("#goo");
  border-radius: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.Cursor span {
  width: 26px;
  height: 26px;
  transform-origin: center;
  background-color: #fff;
  border-radius: 20px;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
}

.stamp {
  z-index: 10;
  position: absolute;
  top: 6vmax;
  right: 6vmax;
}

.stamp p {
  color: #ee3d3d;
  margin: 0;
  font-family: Asul, sans-serif;
  font-size: 2vmax;
}

.stamp .circle {
  width: 1px;
  height: 1px;
  background-color: #0000;
  animation: 9.5s linear infinite rotating;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.stamp .circle span {
  color: #ee3d3d;
  height: 5vmax;
  transform-origin: bottom;
  font-family: Rubik, sans-serif;
  font-size: .8vmax;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.char1 {
  transform: rotate(13deg);
}

.char2 {
  transform: rotate(26deg);
}

.char3 {
  transform: rotate(39deg);
}

.char4 {
  transform: rotate(52deg);
}

.char5 {
  transform: rotate(65deg);
}

.char6 {
  transform: rotate(78deg);
}

.char7 {
  transform: rotate(91deg);
}

.char8 {
  transform: rotate(104deg);
}

.char9 {
  transform: rotate(117deg);
}

.char10 {
  transform: rotate(130deg);
}

.char11 {
  transform: rotate(143deg);
}

.char12 {
  transform: rotate(156deg);
}

.char13 {
  transform: rotate(169deg);
}

.char14 {
  transform: rotate(182deg);
}

.char15 {
  transform: rotate(195deg);
}

.char16 {
  transform: rotate(208deg);
}

.char17 {
  transform: rotate(221deg);
}

.char18 {
  transform: rotate(234deg);
}

.char19 {
  transform: rotate(247deg);
}

.char20 {
  transform: rotate(260deg);
}

.char21 {
  transform: rotate(273deg);
}

.char22 {
  transform: rotate(286deg);
}

.char23 {
  transform: rotate(299deg);
}

.char24 {
  transform: rotate(312deg);
}

.char25 {
  transform: rotate(325deg);
}

.char26 {
  transform: rotate(338deg);
}

.char27 {
  transform: rotate(351deg);
}

.char28 {
  transform: rotate(364deg);
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.newnav-container {
  opacity: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 1512px;
  height: 100%;
  max-height: 60px;
  color: #575755;
  border-radius: 32px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  margin: 16px auto 0;
  padding: 22px 16px 22px 64px;
  transition: all .4s ease-out;
  display: flex;
  overflow: hidden;
}

.newnav-container:hover {
  max-height: 390px;
  background-color: #fff;
  box-shadow: 0 0 40px -15px #00000080;
}

.newnav-container-background {
  background-color: #fff;
  box-shadow: 0 0 40px -15px #00000080;
}

.newnav-logo-container {
  margin-top: -6px;
  margin-right: 16px;
}

.newnav-container-expanded {
  max-height: 390px;
}

.newnav-category-container {
  text-align: left;
}

.newnav-category {
  margin: 0;
  font-size: .75rem;
  font-weight: 600;
}

.newnav-category-dark {
  color: #fff;
}

.newnav-category-light {
  color: #575755;
}

.newnav-category-links {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin-top: 36px;
  display: flex;
}

.newnav-category-link {
  color: #9a9a95;
  font-size: .75rem;
}

.newnav-category-link:hover {
  text-decoration: underline;
}

.newnav-login-container {
  text-align: right;
  flex: auto;
  margin-top: -6px;
}

.newnav-mobile-container {
  height: 60px;
  background-color: #fff;
  display: none;
  position: relative;
}

.newnav-mobile-logo-container {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.newnav-mobile-hamburger-container {
  position: absolute;
  top: 18px;
  left: 16px;
}

.newnav-mobile-categories {
  margin-top: 82px;
}

.newnav-mobile-category-row {
  cursor: pointer;
  justify-content: flex-start;
  margin-top: 16px;
  display: flex;
}

.newnav-right-flex {
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  margin-top: -4px;
  display: flex;
}

.newnav-getjosh-button {
  color: #fff;
  letter-spacing: 2px;
  text-align: center;
  background-color: #008cff;
  border: none;
  border-radius: 100px;
  padding: 10px 20px 12px;
  font-family: Lato;
  font-weight: 700;
  display: block;
}

.newnav-getjosh-button:hover {
  background-color: #0069bf;
}

.newnav-mobile-getjosh-container {
  color: #fff;
  text-align: center;
  background-color: #008cff;
  padding: 8px 0;
  font-size: .9rem;
  display: none;
}

.newnav-mobile-getjosh-link {
  font-size: .9rem;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  #main-nav {
    padding: 0;
    overflow: hidden;
  }

  .newnav-container {
    display: none;
  }

  .newnav-mobile-container, .newnav-mobile-getjosh-container {
    display: block;
  }

  .newnav-mobile-categories {
    padding: 0 16px;
  }

  .newnav-category-container {
    width: -moz-fit-content;
    width: fit-content;
  }

  .newnav-category {
    font-size: 1rem;
  }

  .newnav-category-links {
    margin: 10px 45px 32px;
    font-size: .9rem;
    display: none;
  }

  .newnav-category-link {
    color: #575755;
    font-size: .9rem;
    font-weight: 400;
  }

  .newnav-login-container {
    text-align: left;
    margin-top: 32px;
    margin-left: 33px;
  }

  .dog-logo {
    margin-top: -4px;
  }
}

/*# sourceMappingURL=index.70383f9a.css.map */
