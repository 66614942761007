@font-face {
  font-family: 'nunitoSemi';
  src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'nunitoBold';
  src: url('../fonts/Nunito-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'CGBold';
  src: url('../fonts/CormorantGaramond-Bold.ttf') format('truetype');
}

#floating-header {
  display: none !important;
}
/* COOKIE BANNER */
.cookie-banner.no-show {
  display: none;
}

.cookie-text {
  max-width: 60%;
}

.cookie-banner {
  display: flex;
  position: fixed;
  justify-content: space-around;
  bottom: 0;
  z-index: 103;
  padding: 20px;
  padding-bottom: 40px;
  flex-direction: column-reverse;
  background-color: #242222;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #f0f0f0;
  width: 100%;
}
.cookie-banner a {
  font-size: 12px;
  text-decoration: underline;
}
.cookie-banner a:hover {
  color: #f0f0f0;
}
.cookie-banner span {
  font-weight: bold;
  color: #ffffff;
}
.cookie-close {
  text-align: right;
  padding-right: 50px;
}
.cookie-close img.x-ic {
  width: 25px;
  height: 25px;
  object-fit: contain;
  opacity: 0.8;
  cursor: pointer;
}
.cookie-close img.x-ic:hover {
  opacity: 1;
  transform: scale(1.05);
}

/* MAIN NAV */
.hamburger {
  width: 25px;
  height: 25px;
  position: absolute;
  opacity: 0.8;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  z-index: 10;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-top: 0;
}
.hamburger:hover {
  opacity: 1 !important;
}
.hamburger.x .burgerLine {
  width: 20px;
}
.hamburger .burgerLine {
  height: 2px;
  opacity: 1;
  width: 25px;
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.hamburger.dark .burgerLine {
  background-color: #c5c6bf;
}
.hamburger.light .burgerLine {
  background-color: #fff;
}
.burgerLine1 {
  margin-top: 4px;
}
.burgerLine2 {
  margin-top: 12px;
}
.burgerLine3 {
  margin-top: 20px;
}
#main-nav.dark-theme .links .link-header {
  color: #575755;
}

#main-nav {
  padding: 0 64px;
  background-color: #fff;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
  width: 100%;
  /* height: 60px; */
  left: 0;
  margin-top: 0;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: unset;
  /* transition: height 0.3s ease-out, background-color 0.3s ease, box-shadow .2s ease; */
}

#main-nav.expandedNav {
  background-color: #ffffff;
  box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.5);
  height: 100%;
  overflow: hidden;
  /* transition: height 0.3s ease-out, background-color 0.3s ease, box-shadow .2s ease; */
  transition: height 0.3s ease-out, box-shadow 0.2s ease;
}

#desktop-nav-wrapper {
  background-color: #fff;
  margin: 0 auto;
  max-width: 1512px;
}

.dog-container {
  display: inline-block;
  position: relative;
}

.dog-logo {
  background: url(../img/white-text.svg) no-repeat;
  height: 26px;
  width: 81px;
  background-size: contain !important;
  /* margin-left: 50%; */
  transform: translate(-50%);
  cursor: pointer;
}
.dog-logo.current-logo {
  z-index: 5;
}

.dog-logo.transparent {
  opacity: 0;
}
.dog-logo.faded {
  transition: opacity 0.3s ease-in-out;
}
.dog-logo.dog-swap {
  position: absolute;
  top: 0;
  left: 0;
}
.dog-logo.white-text {
  background: url(../img/white-text.svg) no-repeat;
  z-index: 3;
  position: relative;
}
.dog-logo.dark-text-mob,
.dog-logo.dark-text {
  background: url(../img/dark-text.svg) no-repeat;
  z-index: 3;
}
.dog-logo.white-head {
  background: url(../img/white-dog-head.svg) no-repeat;
}
.dog-logo.dark-head {
  background: url(../img/dark-dog-head.svg) no-repeat;
}

div#backToTop {
  background: url(../img/white-text.svg) no-repeat -9999px -9999px;
}
.info-link-container {
  background: url(../img/dark-text.svg) no-repeat -9999px -9999px;
}
div.new-footer {
  background: url(../img/white-dog-head.svg) no-repeat -9999px -9999px;
}
div.bottom-container {
  background: url(../img/dark-dog-head.svg) no-repeat -9999px -9999px;
}

.link-cont {
  display: flex;
  margin-bottom: 20px;
  /* height: 100%; */
}

.nav-arrow {
  display: block;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.nav-arrow.nav-up {
  background: url(../img/nav-up.svg) no-repeat;
}
.nav-arrow.nav-down {
  background: url(../img/nav-down.svg) no-repeat;
}
.links.initial {
  visibility: hidden;
}

.links {
  position: relative;
  text-align: left;
  display: flex;
  width: calc(100vw - 40px);
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 60px;
  /* overflow: scroll; */
  /* max-height: calc( 100vh - 105px ); */
  /* transition: all .3s; */
}
.links .link-header {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #575755;
  font-family: 'nunitoBold';
  height: 25px;
  line-height: 25px;
  /* transition: all .3s; */
}
.sub-links {
  flex-direction: column;
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  height: 100%;
  /* transition: all .3s; */
}
.sub-links a:hover {
  text-decoration: underline;
}
.sub-links a {
  font-size: 15px;
  font-family: Lato;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #575755 !important;
  margin-bottom: 10px;
  width: 150px;
}
/* END MAIN NAV */

.not-mobile,
.not-tablet {
  display: none !important;
}

::selection {
  background: #333; /* WebKit/Blink Browsers */
  color: white;
}
::-moz-selection {
  background: #333; /* Gecko Browsers */
  color: white;
}

body {
  margin: 0;
  max-width: 100%;
  background-color: #f7f7f7;
}

#newbase-content,
#fullpage {
  background-color: #f7f7f7;
  width: 100%;
  overflow: hidden;
}

.section {
  z-index: 1;
  text-align: center;
  transition: transform 0.5s, top 0.5s, bottom 0.5s;
  top: 0;
  bottom: 0;
}
#section0 {
  background: url('https://d35408cyocioye.cloudfront.net/new3/josh-hero.jpg');
  background-size: cover;
  background-position-x: 50%;
  opacity: 1;
  z-index: 3;
}
#section1 {
  background: url('https://d35408cyocioye.cloudfront.net/new3/josh-introducting-micro-still.jpg');
  background-size: cover;
  background-position-x: 50%;
  opacity: 1;
  z-index: 3;
}

#section2 {
  background: url('../img/josh-getjosh-ski-mobile-2.jpg');
  background-size: cover;
  background-position-x: 50%;
  opacity: 1;
  z-index: 3;
  overflow: hidden;
}
#section3 {
  background: url('https://d35408cyocioye.cloudfront.net/new3/josh-getjosh-shades.jpg');
  background-size: cover;
  background-position-x: 50%;
  opacity: 1;
  z-index: 2;
  overflow: hidden;
  top: -50vh;
}
#section4 {
  background: url('https://d35408cyocioye.cloudfront.net/new3/josh-getjosh-lights-off.jpg');
  /*max-height: 100%;*/
  background-size: cover;
  background-position-x: 50%;
  z-index: 1;
  opacity: 1;
  overflow: hidden;
  top: -100vh;
}
.sectionTextContainer {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.sectionText {
  text-align: center;
  color: #fff;
  opacity: 0;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 280px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
#section1 .sectionText {
  max-width: 880px;
}

.sectionText.dark {
  color: #575755;
}
.sectionTextLarge {
  font-size: 24px;
  font-weight: 300;
}
.microText.sectionTextLarge {
  font-size: 18px;
}

#backToTop {
  display: none;
  opacity: 0;
  height: 30px;
  width: 150px;
  z-index: 102;
  bottom: 60px;
  position: fixed;
  right: -110px;
  border-radius: 5px;
  transition: right 0.2s, opacity 0.5s;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
}
#backToTop:hover {
  opacity: 1 !important;
}
.verticalDownLine {
  height: 15px;
  width: 2px;
  background: #fff;
  position: relative;
  left: 15px;
  top: 25%;
  border-radius: 2px;
}
#backToTop p {
  position: absolute;
  top: 0;
  margin-top: 3px;
  margin-left: 40px;
  color: #fff;
}
.horiDownLine {
  height: 10px;
  width: 2px;
  background: #fff;
  position: absolute;
  top: 6px;
  border-radius: 2px;
}
.horiDownLine1 {
  transform: rotate(45deg);
  left: 12px;
}
.horiDownLine2 {
  transform: rotate(-45deg);
  left: 18px;
}

/**********************
	LANDING SECTION
**********************/
#landingContainer {
  position: fixed;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: height 0.7s, width 0.7s;
  transition: height 0.7s, width 0.7s, background 0.5s;
  background-position-x: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}
.landingContainer1 {
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  background: #fff
    url('https://d35408cyocioye.cloudfront.net/new3/josh-hero.jpg');
  background-size: cover;
  background-position-x: 50%;
}
#landingContainer iframe {
  display: none;
}

.landingContainer2 {
  width: 90%;
  height: 60%;
  background: #fff
    url(https://d35408cyocioye.cloudfront.net/new3/josh-introducting-micro-still.jpg);
  background-size: cover;
  background-position-x: 50%;
  -webkit-box-shadow: 0px 10px 15px -2px rgba(161, 161, 161, 0.49);
  -moz-box-shadow: 0px 10px 15px -2px rgba(161, 161, 161, 0.49);
  box-shadow: 0px 10px 15px -2px rgba(161, 161, 161, 0.49);
  cursor: pointer;
}
.landingContainer2.grow {
  width: 75%;
  height: calc(0.5625 * 75vw);
}
.landingContainer2:hover {
  -webkit-box-shadow: 0px 10px 15px -2px rgba(120, 120, 120, 0.49);
  -moz-box-shadow: 0px 10px 15px -2px rgba(120, 120, 120, 0.49);
  box-shadow: 0px 10px 15px -2px rgba(120, 120, 120, 0.49);
}

#landingJoshLogo {
  height: 52px;
  width: 150px;
  transform: scale(0.75);
  background: url(../img/white-text.svg) no-repeat;
  background-size: cover;
  margin: auto;
  margin-bottom: 20px;
  opacity: 0;
  transition: opacity 300ms;
}
.landingCopy {
  opacity: 0;
  transition: opacity 500ms;
}
.landingCopyContainer {
  transition: opacity 300ms;
}
.secondCopyContainer {
  opacity: 0;
  transition: opacity 500ms;
}
#landingVid {
  pointer-events: none;
  overflow-y: hidden;
  display: block;
  position: absolute;
  height: 100%;
  z-index: -100;
  object-fit: cover;
  width: 100%;
  pointer-events: none;
  opacity: 0;
}
#landingVid2 {
  overflow-y: hidden;
  display: block;
  position: absolute;
  height: 100%;
  object-fit: cover;
  width: 100%;
  z-index: -100;
  opacity: 0;
}

#secondCopy {
  display: block;
  /*padding-top: 50%;
	height: 100%;*/
  z-index: 1;
}
.fade-in-video {
  opacity: 0;
  transition: opacity 0.5s linear;
}
.fade-in-video.is-playing {
  opacity: 1 !important;
}
#mainVideo {
  position: relative;
  width: 100%;
  height: 560px;
  padding-top: 46px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(https://d35408cyocioye.cloudfront.net/new2/video.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
#mainVideo:hover {
  cursor: pointer;
}
#mainVideo:hover > .integratorTopPlayButton {
  cursor: pointer;
  background-position: 0px 51px;
}
.ignore-css {
  all: unset;
}
.landingPlayButton {
  height: 39px;
  width: 39px;
  margin: auto;
  margin-top: 15px;
  background: url(https://d35408cyocioye.cloudfront.net/new3/ic-play.svg)
    no-repeat;
  opacity: 0.7;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.landingPlayButton:hover {
  /* opacity: 1 !important; */
  cursor: pointer;
}
@keyframes scrollDown {
  from {
    height: 0;
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    height: 100px;
    opacity: 1;
    transform: translateY(100px);
  }
}

.downArrowLine {
  height: 0px;
  width: 1px;
  top: 0;
  left: 49%;
  background-color: #fff;
  position: absolute;
  -webkit-transition: all 1s ease-in;
  transition: all 1s ease-in;
  opacity: 0;
}
.downArrowLine.animated {
  animation: scrollDown 0.7s infinite;
}
.scrollDownArrow {
  height: 35px;
  width: 30px;
  margin: auto;
  margin-top: 10px;
  left: 50%;
  opacity: 0;
  position: absolute;
  bottom: 35px;
  -webkit-transition: all 1s;
  transition: all 1s;
  transform: translateX(-50%);
  z-index: 13;
  cursor: pointer;
}
.arrowLines {
  height: 1px;
  width: 0px;
  top: 50%;
  background-color: #fff;
  position: absolute;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.arrowLines.darkArrow {
  background-color: #999;
}
.scrollDownArrow.dark > .arrowLines {
  background-color: #000;
}
.arrowLines1 {
  left: 50%;
}
.arrowLines2 {
  right: 50%;
}

/**********************
	FULLSCREEN NAV
**********************/
.fullNav {
  background-color: #272a2d;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 0;
  position: fixed;
  height: 100vh;
  top: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  left: 0;
  width: 100vw;
  opacity: 0;
  display: none;
  -webkit-backface-visibility: hidden;
}
.fullNavList {
  margin-top: 6vh;
  padding-left: 0;
  height: 110vh;
}
#fullNavListLeft {
  width: 70%;
  padding-left: 30vw;
}
#fullNavListMid {
  position: absolute;
  margin-top: calc(6vh + 78px);
  left: 45vw;
  transform: translateX(-40%);
}
#fullNavListRight {
  position: absolute;
  left: 30vw;
  margin-top: calc(6vh + 760px);
  height: auto;
}
.fullNavText {
  margin-bottom: 15px;
  list-style: none;
  text-align: left;
  transform: translate3d(-30px, 0px, 0);
  opacity: 0;
}
.fullNavText.view,
.horizontalAboutLine.view {
  transition: all 0.15s ease-out;
  transform: translate3d(0px, 0, 0);
  opacity: 1;
}
.horizontalAboutLine.view {
  opacity: 0.25;
  transition: all 0.2s ease-out;
}
.horizontalAboutLine.view::before {
  width: 7vw;
  transition: all 0.3s ease-out;
}
.fullNavText.leave,
.horizontalAboutLine.leave {
  transition: all 0.05s ease-in;
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
}
.fullNavText.active {
  border-left: 2px #008cff solid;
  padding-left: 20px;
  margin-left: -20px;
}
.fullNavText.active a {
  opacity: 0.9;
}
.fullNavText a {
  opacity: 0.7;
  position: relative;
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;
}
.aboutLine {
  width: 100%;
  height: 1px;
  display: block;
  position: relative;
  top: -1rem;
  left: 6rem;
  background: white;
}
.horizontalAboutLine {
  width: 0px;
  height: 11rem;
  position: absolute;
  top: 0.25rem;
  margin-left: -20px;
  opacity: 0.25;
  border: solid 1px #ffffff;
}
.horizontalAboutLine::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 4rem;
  margin-left: -7.2vw;
  border: none;
}

/**********************
	HEADER
**********************/
#fixedHeader.pinned {
  position: absolute !important;
}

#fixedHeader {
  height: 40px;
  left: 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 12;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-backface-visibility: hidden;
}
.fixedHeaderPosition {
  width: calc(100% - 100px);
  padding-left: 20px;
  padding-right: 50px;
  margin-top: 10px;
}
.fixedHeaderSplash {
  width: calc(100% - 100px);
  padding-left: 20px;
  padding-right: 50px;
  margin-top: 10px;
}
#joshdoghead {
  height: auto;
  width: 115px;
  background: url(../img/white-text.svg) no-repeat;
  background-size: cover;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.headerLogin {
  color: #fff;
  line-height: 40px;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.headerLogin:hover {
  opacity: 1 !important;
}

/**********************
	FEATURE SECTIONS
**********************/
.featureVideo {
  pointer-events: none;
  overflow-y: hidden;
  display: block;
  position: absolute;
  z-index: -100;
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.replay {
  width: 35px;
  height: 40px;
  object-fit: contain;
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 50px;
  margin-bottom: 25px;
  transition: all 0.5s ease-in;
  cursor: pointer;
}
.replayVisible {
  opacity: 1;
}
.replayVisible:hover {
  opacity: 0.8;
}

.heyJoshContainer {
  position: fixed;
  width: 95vw;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  color: #fff;
  opacity: 0;
  left: 5vw;
  font-size: 3rem;
  font-weight: 300;
  text-align: left;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-backface-visibility: hidden;
}
.heyJoshText {
  -webkit-transition: opacity;
  transition: opacity;
}
.heyJoshRequest {
  border-bottom: none;
  padding-bottom: 6.5px;
  width: auto;
  display: inline-block;
  -webkit-transition: opacity 300ms, transform 500ms;
  transition: opacity 300ms, transform 500ms;
  opacity: 0;
  margin-top: 1rem;
}

/**********************
	FOOTER
**********************/
.new-footer {
  z-index: 100;
  position: relative;
}
.new-footer_next {
  z-index: 100;
  position: relative;
  width: 100vw;
  background-color: #fff;
  overflow-y: hidden;
}
.next-container.long {
  width: 80% !important;
}
.next-container {
  width: 50%;
  cursor: pointer;
  margin: 15vh 0;
  margin-left: auto;
  margin-right: auto;
}
.next-up-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.next-up-header {
  font-family: 'nunitoBold';
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: #575755;
  margin-bottom: 40px;
  max-width: 100vw;
}

/* NEW PRODUCTS FOOTER */
.next-container.new-prod-foot {
  width: 80%;
  margin: 80px 0 100px 0;
  margin-left: auto;
  margin-right: auto;
}
.next-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.next-item.get {
  margin-right: 0px !important;
}
.next-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
  transition: all 0.3s;
}
.next-item:hover {
  transform: scale(1.2);
}
.next-img.nano {
  background-image: url('https://joshai.s3.amazonaws.com/new3/homepage/next-up-nano.png');
  height: 50px;
  width: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.next-img.micro {
  background-image: url('https://joshai.s3.amazonaws.com/new3/homepage/next-up-micro.png');
  height: 100px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.next-img.get {
  background-image: url('https://joshai.s3.amazonaws.com/new3/homepage/next-up-get-josh.png');
  height: 85px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.next-title {
  margin-bottom: 15px;
  font-family: 'nunitoBold';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #575755;
}

.new-footer_info {
  width: 100vw;
  background-color: #111;
  overflow-y: hidden;
}
.info-container {
  display: flex;
  width: 85vw;
  justify-content: space-between;
  margin: auto;
  padding: 40px 0;
  flex-direction: column;
  max-width: 500px;
}
.info-link-container {
  display: flex;
  flex-direction: column;
}
.info-links {
  display: flex;
  margin-left: 0;
  align-items: center;
  flex-direction: column;
}
.info-link-col {
  display: flex;
  align-self: center;
}
.info-link-line {
  display: none;
  width: 0px;
  height: 200px;
  opacity: 0.3;
  border: solid 1px white;
}
.info-link {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  opacity: 0.6;
  letter-spacing: normal;
  margin-bottom: 20px;
}
.info-link:nth-child(4) {
  margin-bottom: 0;
}
.info-link:hover {
  opacity: 1;
}

.info-story-content {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #bbc6d0;
  width: 100%;
  text-align: justify;
}
.info-story-text {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: white;
}
.info-story-dog img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-bottom: 15px;
  margin-right: 15px;
}
.info-story-header {
  display: flex;
}
.info-story-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
}
.info-award-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
}
.info-award img {
  height: 50px;
}
.info-award {
  margin-bottom: 20px;
}
.info-award:nth-child(3) {
  margin-bottom: 0;
}
.info-award.award-badge {
  display: flex;
  align-items: center;
}
.award-badge img {
  width: 42px;
}
.award-title {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.8;
  letter-spacing: normal;
  color: #bbc6d0;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 43px;
}
.award-title span {
  font-weight: bold;
}

.new-footer_bottom {
  width: 100vw;
  background-color: #000;
  overflow-y: hidden;
}
.bottom-container {
  display: flex;
  width: 85vw;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 25px 0;
}
.bottom-social-container {
  margin-bottom: 20px;
  display: flex;
}
.bottom-social img {
  opacity: 0.7;
  margin-top: 5px;
  transform: scale(1) !important;
  height: 19px;
  width: 19px;
  padding-right: 20px;
  margin-right: 20px;
}
.bottom-social img:hover {
  opacity: 1;
}

.bottom-link {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #bbc6d0;
}
a .bottom-link:hover {
  color: white;
}

.bottom-link-container.mobile-bottom-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 350px;
}
.bottom-link-container.desk-bottom-links {
  display: none;
}
.bottom-link-container a {
  margin-right: 0;
}
.bottom-link.bottom-privacy {
  margin-bottom: 10px;
  margin-right: 40px;
}

#sectionFoot {
  display: none;
}

#email_status.enterText {
  color: #fff;
  font-size: 11px;
  margin-left: 15px;
  margin-bottom: 80px;
  position: absolute;
}

.nextUp {
  position: absolute;
  bottom: 50vh;
  height: 50vh;
  width: 100vw;
  background: white;
}

.footerTextContainer {
  bottom: 20vh;
  position: absolute;
  width: 90%;
  cursor: pointer;
  /*bottom: 5vh;
  position: absolute;
  transform: translateX(-12%);
  top: 40%;
  left: 20%;
  width: 80%;*/
  cursor: pointer;
}
.footerText {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.67;
  text-align: center;
  color: #575755;
}
.footerTextMain:hover {
  text-decoration: underline;
}
.footerTextSecondary {
  color: #9a9a95;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 5px;
  text-align: center;
}
.rightArrow {
  top: 50%;
  position: absolute;
  cursor: pointer;
}
.rightArrowLines {
  background-color: #008cff;
  height: 12px;
  position: absolute;
  width: 2px;
}
.rightArrowLines:hover {
  background-color: #5c7385;
}
.rightArrowLines1 {
  transform: rotate(-45deg);
}
.rightArrowLines2 {
  transform: rotate(45deg);
  margin-top: 8px;
}

.fullFooter {
  background: #303c45;
  position: absolute;
  height: 60vh;
  width: 100%;
  bottom: 0;
}
.fullFooterText {
  margin-bottom: 1rem;
  list-style: none;
  text-align: left;
  opacity: 0.7;
}
.fullFooterText a {
  font-weight: 300;
  position: relative;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}
.fullFooterText:hover {
  opacity: 1;
}
.footerLeftLinks {
  margin-left: 20vw;
  top: 55%;
  transform: translateY(-50%);
  position: absolute;
}
.footerRightLinks {
  right: 20vw;
  top: 55%;
  transform: translateY(-50%);
  position: absolute;
}
.footerDivider {
  width: 0px;
  height: 17.5vh;
  left: 50%;
  top: 37.5%;
  transform: translateY(-50%);
  opacity: 0.25;
  border: solid 1px #ffffff;
  position: absolute;
}
.footerEmail {
  top: 37.5%;
}
.joinText {
  font-size: 15px;
  width: 100%;
  font-weight: bold;
  letter-spacing: 5px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, 50%);
  cursor: pointer;
}
#email_signup {
  /* position: absolute;
	transform: translate(-50%, -50%);
  top: 17%;
  left: 50%;
  width: 80%;
	display: none; */
}
#emailform {
  width: 235px;
  height: 40px;
  border-radius: 100px;
  border: solid 2px #bbc6d0;
  outline: none;
  opacity: 0.5;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  -webkit-highlight: none;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: white;
  padding-left: 15px;
  padding-right: 70px;
  background: none;
}
#emailform:focus {
  opacity: 1 !important;
}
.bottom-email-container {
  margin-bottom: 30px;
  height: 130px;
}
.btn-bg {
  margin-left: -80px;
  cursor: pointer;
  background: none;
  opacity: 0.5;
  border-radius: 23px;
  border: none;
  outline-width: 0;
}
.btn-txt {
  height: 15px;
  padding: 20px 0;
  width: 70px;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  transform: translateY(-1px);
  position: relative;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff;
}
.btn-bg:hover {
  opacity: 1;
}

.bottomFooter {
  background: #263037;
  position: absolute;
  height: 20%;
  width: 100%;
  bottom: 0;
}
.bottomFooterLayout {
  flex-direction: column;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  position: relative;
}
.bottomFooterText {
  opacity: 0.5;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding-right: 4vw;
}
.bottomFooterLayout a .bottomFooterText {
  opacity: 0.5;
  font-weight: 500;
}
/*.bottomFooterText:hover{
	opacity: 1;
}*/
.bottomFooterLayout a:hover > .bottomFooterText {
  opacity: 1;
}
.socialIcons {
  padding: 0 4vw;
  height: 36px;
}
.socialIcons img {
  opacity: 0.7;
  margin-top: 5px;
  transform: scale(1) !important;
}
.socialIcons img:nth-child(1) {
  height: 19px;
  width: 19px;
}
.socialIcons img:nth-child(2) {
  height: 20px;
  width: 10px;
}
.socialIcons img:nth-child(3) {
  height: 24px;
  width: 24px;
}
.socialIcons img:hover {
  opacity: 1;
}

.priTermsContainer {
  display: inline-flex;
}

.bottomFooter img {
  height: fit-content;
  padding-right: 8vw;
  transform: scale(0.8);
}
#instaLogo {
  padding-right: 0;
  margin-right: 0;
}

/**********************
	MICRO SECTION
**********************/
.microSection {
  background-color: #f7f7f7;
  background-size: cover;
  background-position-x: 50%;
  opacity: 1;
  z-index: 3;
  top: -100vh;
  overflow: hidden;
}
.lastSection {
  background-color: #f7f7f7;
  top: -100vh;
  z-index: 5;
  opacity: 1;
  height: 50%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.microContainer {
  display: none;
}

.wrapper {
  height: 20vh;
  overflow-y: scroll;
  margin: 0 auto;
}

.micro-anim {
  background: url('https://d35408cyocioye.cloudfront.net/new3/microsegments/micro-anim-0.jpg');
  background-size: cover;
  opacity: 1;
  z-index: 0;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-position: center;
  -webkit-backface-visibility: hidden;
  display: none;
}
.micro-anim.locked {
  background: url('https://d35408cyocioye.cloudfront.net/new3/microsegments/micro-anim-31.jpg') !important;
  display: none !important;
}

.microText {
  opacity: 0 !important;
  top: 10%;
  position: absolute;
  width: 75%;
  left: 50vw;
  transform: translate(-50%, 0);
  -webkit-transition: opacity 0.5s, top 0.1s;
  transition: opacity 0.5s, top 0.1s;
  -webkit-backface-visibility: hidden;
}
.microTextSecondary {
  opacity: 0 !important;
  position: absolute;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.75;
  text-align: center;
  color: #575755;
  width: 90vw;
  left: 5vw;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  -webkit-backface-visibility: hidden;
}
#microTextSecondary1 {
  top: 25%;
}
#microTextSecondary2 {
  top: 40%;
}
#microTextSecondary3 {
  top: 57%;
}
#microTextSecondary4 {
  top: 75%;
}

.microTextSecondary p {
  font-size: 15px;
  margin-top: 5px;
  line-height: 1.67;
  color: #9a9a95;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.microText.shown {
  opacity: 1 !important;
  /*top: 50px;*/
}
.microTextSecondary.shown {
  opacity: 1 !important;
  transition: opacity 3s;
  transition-delay: 0.5s;
}
#transMicro {
  height: 100%;
  width: 100%;
  background: #999;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: none;
  background: url('https://d35408cyocioye.cloudfront.net/new3/microsegments/micro-anim-freeze-31.png');
  background-size: cover;
  background-position: center;
  -webkit-backface-visibility: hidden;
}
#verticalLine {
  width: 3px;
  height: 70vh;
  position: absolute;
  background-color: #999;
  left: 50vw;
  top: 50vw;
  opacity: 0.5;
}
.lastMicroTextContainerR {
  color: #575755;
  height: 70vh;
  position: absolute;
  left: 49vw;
  width: 50vw;
  top: 50vw;
  z-index: 1;
  text-align: left;
}
.lastMicroTextContainerL {
  color: #575755;
  height: 70vh;
  position: absolute;
  width: 52vw;
  top: 50vw;
  text-align: right;
  z-index: 1;
}
#lastMicroBR {
  top: 45vh;
  position: relative;
}
#lastMicroTLContainer {
  display: inline-flex;
  top: 5vh;
  position: relative;
}
#lastMicroTL {
  width: 40vw;
  position: relative;
  left: -1vw;
}
#lastMicroBL {
  position: absolute;
  left: 52vw;
  width: 40vw;
  top: 70vh;
  transform: translate(-100%, -70%);
}
#lastMicroTR {
  top: -10px;
  position: relative;
}
#lastMicro {
  height: 40%;
  width: 100%;
  position: relative;
  top: 50vh;
  transform: translateY(-30%);
  background: url(../img/third_section.png);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-backface-visibility: hidden;
}
#transMicro.big {
  height: 400px;
  width: 400px;
  top: 64%;
}
#transMicro.transVisible {
  display: block;
}

#brandWall {
  position: absolute;
  top: 50%;
  display: flex;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  left: 0;
  transform: translateY(-50%);
  animation: scrollAcross 50s infinite linear;
  /*transition: opacity 25ms;*/
}
#brandWall.activated {
  opacity: 1;
}
.brandCol {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
}
.brandCol img {
  width: 120px;
  height: 60px;
  margin-bottom: 20px;
}

@keyframes scrollAcross {
  from {
    left: 0;
  }

  to {
    left: -2520px;
  }
}

@keyframes centerOutFirst {
  from {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  to {
    left: 17vw;
    top: 30%;
  }
}
@keyframes centerOutSecond {
  from {
    right: 50%;
    top: 50%;
    transform: translate(100%, -50%);
  }

  to {
    right: 17vw;
    top: 30%;
  }
}
@keyframes centerOutThird {
  from {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  to {
    left: 17vw;
    top: 60%;
  }
}
@keyframes centerOutFourth {
  from {
    right: 50%;
    top: 50%;
    transform: translate(100%, -50%);
  }

  to {
    right: 17vw;
    top: 60%;
  }
}

/* FOOTER ANIM */
svg {
  display: none;
}
.page {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: block;
}
.container {
  width: 100%;
  height: 100%;
  display: table;
  z-index: 100;
}
.child {
  display: table-row;
}
.content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.HoverButton {
  position: relative;
  display: inline-block;
  z-index: 20;
  padding: 0 5px;
  overflow: hidden;
}
.HoverButton .bg {
  pointer-events: none;
  position: absolute;
  background-color: #000;
  height: 100%;
  width: 125%;
  display: block;
  -webkit-transform: translateX(-112%) skew(-10deg);
  transform: translateX(-112%) skew(-10deg);
}
h1 {
  color: #000;
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  position: relative;
  z-index: 1;
  margin: 0;
  mix-blend-mode: difference;
}
.HoverButton:hover > h1 {
  color: #fff;
}
#cursor {
  display: none;
}
#cursor.visible {
  display: block;
}
.Cursor {
  pointer-events: none;
  position: fixed;
  display: block;
  border-radius: 0;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  mix-blend-mode: difference;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-filter: url('#goo');
  filter: url('#goo');
}
.Cursor span {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 20px;
  background-color: #fff;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.stamp {
  position: absolute;
  right: 6vmax;
  top: 6vmax;
  z-index: 10;
}
.stamp p {
  color: #ee3d3d;
  font-size: 2vmax;
  font-family: 'Asul', sans-serif;
  margin: 0;
}
.stamp .circle {
  position: absolute;
  width: 1px;
  height: 1px;
  display: block;
  left: 50%;
  top: 50%;
  background-color: transparent;
  -webkit-animation: rotating 9.5s linear infinite;
  animation: rotating 9.5s linear infinite;
}
.stamp .circle span {
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  font-size: 0.8vmax;
  color: #ee3d3d;
  height: 5vmax;
  position: absolute;
  display: inline-block;
  left: 0;
  bottom: 0;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}
.char1 {
  -webkit-transform: rotate(13deg);
  transform: rotate(13deg);
}
.char2 {
  -webkit-transform: rotate(26deg);
  transform: rotate(26deg);
}
.char3 {
  -webkit-transform: rotate(39deg);
  transform: rotate(39deg);
}
.char4 {
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
}
.char5 {
  -webkit-transform: rotate(65deg);
  transform: rotate(65deg);
}
.char6 {
  -webkit-transform: rotate(78deg);
  transform: rotate(78deg);
}
.char7 {
  -webkit-transform: rotate(91deg);
  transform: rotate(91deg);
}
.char8 {
  -webkit-transform: rotate(104deg);
  transform: rotate(104deg);
}
.char9 {
  -webkit-transform: rotate(117deg);
  transform: rotate(117deg);
}
.char10 {
  -webkit-transform: rotate(130deg);
  transform: rotate(130deg);
}
.char11 {
  -webkit-transform: rotate(143deg);
  transform: rotate(143deg);
}
.char12 {
  -webkit-transform: rotate(156deg);
  transform: rotate(156deg);
}
.char13 {
  -webkit-transform: rotate(169deg);
  transform: rotate(169deg);
}
.char14 {
  -webkit-transform: rotate(182deg);
  transform: rotate(182deg);
}
.char15 {
  -webkit-transform: rotate(195deg);
  transform: rotate(195deg);
}
.char16 {
  -webkit-transform: rotate(208deg);
  transform: rotate(208deg);
}
.char17 {
  -webkit-transform: rotate(221deg);
  transform: rotate(221deg);
}
.char18 {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.char19 {
  -webkit-transform: rotate(247deg);
  transform: rotate(247deg);
}
.char20 {
  -webkit-transform: rotate(260deg);
  transform: rotate(260deg);
}
.char21 {
  -webkit-transform: rotate(273deg);
  transform: rotate(273deg);
}
.char22 {
  -webkit-transform: rotate(286deg);
  transform: rotate(286deg);
}
.char23 {
  -webkit-transform: rotate(299deg);
  transform: rotate(299deg);
}
.char24 {
  -webkit-transform: rotate(312deg);
  transform: rotate(312deg);
}
.char25 {
  -webkit-transform: rotate(325deg);
  transform: rotate(325deg);
}
.char26 {
  -webkit-transform: rotate(338deg);
  transform: rotate(338deg);
}
.char27 {
  -webkit-transform: rotate(351deg);
  transform: rotate(351deg);
}
.char28 {
  -webkit-transform: rotate(364deg);
  transform: rotate(364deg);
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.newnav-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 40px;
  /* start at transparent, then make opaque when we know theme to load */
  opacity: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 1512px;
  height: 100%;
  max-height: 60px;
  color: #575755;
  margin: 16px auto 0;
  padding: 22px 16px 22px 64px;
  overflow: hidden;
  border-radius: 32px;
  transition: all 0.4s ease-out;
}

.newnav-container:hover {
  max-height: 390px;
  background-color: #fff;
  box-shadow: 0 0 40px -15px #00000080;
}

.newnav-container-background {
  background-color: #fff;
  box-shadow: 0 0 40px -15px #00000080;
}

.newnav-logo-container {
  margin-top: -6px;
  margin-right: 16px;
}

.newnav-container-expanded {
  max-height: 390px;
}

.newnav-category-container {
  text-align: left;
}

.newnav-category {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 600;
}
.newnav-category-dark {
  color: #fff;
}
.newnav-category-light {
  color: #575755;
}

.newnav-category-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 36px;
}

.newnav-category-link {
  font-size: 0.75rem;
  color: #9a9a95;
}

.newnav-category-link:hover {
  text-decoration: underline;
}

.newnav-login-container {
  flex: 1 1 auto;
  text-align: right;
  margin-top: -6px;
}

.newnav-mobile-container {
  display: none;
  position: relative;
  height: 60px;
  background-color: #fff;
}

.newnav-mobile-logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  height: 100%;
}

.newnav-mobile-hamburger-container {
  position: absolute;
  top: 18px;
  left: 16px;
}

.newnav-mobile-categories {
  margin-top: 82px;
}

.newnav-mobile-category-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  cursor: pointer;
}

.newnav-right-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -4px;
  gap: 40px;
}

.newnav-getjosh-button {
  background-color: #008cff;
  color: #fff;
  border: none;
  letter-spacing: 2px;
  text-align: center;
  padding: 10px 20px 12px;
  font-family: Lato;
  font-weight: 700;
  display: block;
  border-radius: 100px;
}

.newnav-getjosh-button:hover {
  background-color: #0069bf;
}

.newnav-mobile-getjosh-container {
  background-color: #008cff;
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  padding: 8px 0px;
  display: none;
}

.newnav-mobile-getjosh-link {
  text-decoration: underline;
  font-size: 0.9rem;
}

@media (max-width: 1024px) {
  #main-nav {
    overflow: hidden;
    padding: 0;
  }
  .newnav-container {
    display: none;
  }
  .newnav-mobile-container {
    display: block;
  }

  .newnav-mobile-getjosh-container {
    display: block;
  }

  .newnav-mobile-categories {
    padding: 0 16px;
  }

  .newnav-category-container {
    width: fit-content;
  }

  .newnav-category {
    font-size: 1rem;
  }

  .newnav-category-links {
    margin: 10px 45px 32px;
    font-size: 0.9rem;
    display: none;
  }

  .newnav-category-link {
    font-size: 0.9rem;
    font-weight: 400;
    color: #575755;
  }

  .newnav-login-container {
    margin-left: 33px;
    text-align: left;
    margin-top: 32px;
  }

  .dog-logo {
    margin-top: -4px;
  }
}
